import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Report from '@dbt/report';
import { message } from 'ant-design-vue';
/**
 * Create by zhuzhaoqing
 * Description: index
 * Date: 2023/7/5 11:51
 **/
export const OK = '0';
export const dateFormat = 'YYYY-MM-DD HH:mm:ss';
export const dateFormatNoTime = 'YYYY-MM-DD';
export const getSessionStorage = key => {
  return sessionStorage.getItem(key);
};
export const setSessionStorage = (key, value) => {
  return sessionStorage.setItem(key, value);
};
export const clearSessionStorage = key => {
  return sessionStorage.removeItem(key);
};
export const copy = (e, type = 'input') => {
  if (!e || e.trim().length == 0) {
    message.warning('不可复制空值');
  } else if (e) {
    const Url2 = e;
    const oInput = document.createElement(type);
    oInput.value = Url2;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy'); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    message.success('复制成功');
  }
};
export const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
export const resetData = (formName, initForm) => {
  const keys = Object.keys(formName);
  const obj = {};
  keys.forEach(item => {
    obj[item] = initForm[item];
  });
  Object.assign(formName, obj);
};
// 处理async try catch
export async function awaitWrap(promise) {
  try {
    const data = await promise;
    return [null, data];
  } catch (err) {
    return [err, null];
  }
}
// 下拉组件不随页面滚动
export const getNode = triggerNode => triggerNode.parentNode || document.body;
// 处理导出文件 blob格式
export const handleExportFile = (data, name, type = 'xlsx') => {
  const blob = new Blob([data], {
    type: 'application/vnd.ms-excel'
  });
  const url = window.URL.createObjectURL(blob);
  const downloadElement = document.createElement('a');
  downloadElement.href = url;
  downloadElement.download = `${name}.${type}`;
  downloadElement.click();
  URL.revokeObjectURL(downloadElement.href);
};
// 全局标志，表示是否已经初始化
let isReportInitialized = false;
window.__isReportInitialized = false;
// 事件上报
export const reportEvent = async params => {
  // 确保已经初始化
  if (!isReportInitialized) {
    // 等待初始化完成（最多等待3秒）
    // 添加一个标志来跟踪Promise是否已解析
    let promiseResolved = false;
    await new Promise(resolve => {
      const checkInterval = setInterval(() => {
        if (isReportInitialized && !promiseResolved) {
          clearInterval(checkInterval);
          promiseResolved = true;
          resolve(true);
        }
      }, 100);
      // 3秒后超时
      setTimeout(() => {
        if (!promiseResolved) {
          clearInterval(checkInterval);
          promiseResolved = true;
          resolve(false);
        }
      }, 3000);
    });
  }
  Report.reportEvent(params);
};
// 事件初始化
export const reportInit = async info => {
  var _info$parentOrgNames;
  const params = {
    username: info.username,
    dbtId: 'f571145af9250ef7416c79e53',
    chnl: (info === null || info === void 0 || (_info$parentOrgNames = info.parentOrgNames) === null || _info$parentOrgNames === void 0 ? void 0 : _info$parentOrgNames.join('/')) + '/' + (info === null || info === void 0 ? void 0 : info.department),
    name: info.name,
    env: process.env.VUE_APP_IS_SENTRY === 'true' ? '' : 'test'
  };
  try {
    await Report.init(params);
    // 标记初始化完成
    isReportInitialized = true;
    window.__isReportInitialized = true;
  } catch (error) {
    // 即使失败也标记为已初始化，避免卡死
    isReportInitialized = true;
    window.__isReportInitialized = true;
  }
};
// 首字母大写
export const firstUpperCase = word => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export const detailFields = ref([{
  label: '所属项目',
  key: 'productName'
}, {
  label: '商店名',
  key: 'filePath'
}, {
  label: '主包名',
  key: 'pkg'
}, {
  label: 'AppID',
  key: 'appId'
}, {
  label: 'DBTID',
  key: 'dbtId'
}]); // 产品详情字段
export const searchFields = ['label', 'description', 'dbtId', 'appName', 'studio', 'pkg', 'appId', 'filePath']; // 产品搜索字段
export const getAppListInformation = data => {
  var _data$productName, _data$filePath, _data$pkg, _data$appId, _data$dbtId;
  if (!data) return [];
  return [{
    label: '所属项目',
    value: (_data$productName = data.productName) !== null && _data$productName !== void 0 ? _data$productName : '-'
  },
  // { label: '项目负责人', value: data.projectManager ?? '-' },
  {
    label: '商店名',
    value: (_data$filePath = data.filePath) !== null && _data$filePath !== void 0 ? _data$filePath : '-'
  }, {
    label: '主包名',
    value: (_data$pkg = data.pkg) !== null && _data$pkg !== void 0 ? _data$pkg : '-'
  }, {
    label: 'AppID',
    value: (_data$appId = data.appId) !== null && _data$appId !== void 0 ? _data$appId : '-'
  }, {
    label: 'DBTID',
    value: (_data$dbtId = data.dbtId) !== null && _data$dbtId !== void 0 ? _data$dbtId : '-'
  }];
};