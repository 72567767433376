/*
 * @Author: xuhuang xuhuang@wedobest.com.cn
 * @Date: 2023-03-02 11:53:24
 * @LastEditors: xuhuang xuhuang@wedobest.com.cn
 * @LastEditTime: 2023-03-24 11:31:30
 * @FilePath: main.ts
 * @Description:
 */
import './public-path'
import { createApp, watch } from 'vue'
import App from './App.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import router from './router'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// import { ant } from './plugins/index'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import { createPinia } from 'pinia'
import './styles/index.less'
import './icons'
import * as Sentry from '@sentry/vue'
import { useStore } from './store'
import Report from '@dbt/report'

const pinia = createPinia()

const app = createApp(App)

// 针对页面中的操作增加了权限判断 读、读写权限
// 两个传值 permissionValue:对应页面 permissionArr:userInfo中的权限数组
app.directive('permission', {
  mounted(el, binding) {
    const store = useStore()
    const checkPermission = () => {
      const userInfo = store.getParsedUserInfo
      if (!userInfo) {
        el.style.display = 'none'
        return
      }

      const permissions = userInfo.permissions
      const permissionValue = binding.value?.permissionValue

      // 如果有完全权限(*) 则显示
      if (permissions?.some((item: string) => item === '*')) {
        el.style.display = ''
        return
      }

      // 检查具体权限
      const hasPermission = permissions?.some(
        (item: string) => item === `${permissionValue}:*` || item === permissionValue,
      )

      el.style.display = hasPermission ? '' : 'none'
    }

    // 初始检查
    checkPermission()

    // 监听 userInfo 变化
    watch(
      () => store.getParsedUserInfo,
      () => checkPermission(),
      { immediate: true },
    )
  },

  // 组件更新时重新检查权限
  updated(el, binding) {
    const store = useStore()
    const userInfo = store.getParsedUserInfo
    if (!userInfo) {
      el.style.display = 'none'
      return
    }

    const permissions = userInfo.permissions
    const permissionValue = binding.value?.permissionValue

    if (permissions?.some((item: string) => item === '*')) {
      el.style.display = ''
      return
    }

    const hasPermission = permissions?.some(
      (item: string) => item === `${permissionValue}:*` || item === permissionValue,
    )

    el.style.display = hasPermission ? '' : 'none'
  },
})

if (process.env.VUE_APP_IS_SENTRY === 'true') {
  Sentry.init({
    app,
    dsn: 'https://9127f634979d4baef5cb1c1e918f010c@sentry.wedobest.com.cn/7',
    release: process.env.VUE_APP_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          'https://payadmin.wedobest.com.cn:8450',
          'https://payadmin.wedobest.com.cn',
          'https://gateway.wedobest.com.cn:8465',
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(router)
app.use(Antd)
app.use(pinia)
router.isReady().then(() => {
  app.mount('#app')
})

// 添加全局的beforeunload监听器
window.addEventListener('beforeunload', () => {
  Report.clearPreviousObservers()
  console.log('应用关闭，性能监控已清理')
})
